import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_rollup@2.79.2_sass@1.82.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/buildhome/repo/app/router.options.js";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}